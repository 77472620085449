import React from "react"

const SouzokuKettei = () => {
  const title = `相続方法の決定`
  const content = `相続の調査を終えたら、どのような形で相続をするのか、または、相続を放棄するのかを決定します。`
  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．相続放棄（限定承認）について</dt>
            <dd>
              相続放棄（限定承認）は、３ヶ月以内に家庭裁判所に申述することによって、
              初めから相続人とならなかったとみなされます。
              <br />
              ただし、相続財産を処分してしまったり、３ヶ月経過してしまうと、
              原則として、相続放棄できないことになります。
              <br />
              しかしながら、単に３ヶ月経過したからといって、全く相続放棄が
              認められなくなるわけではありませんので、その場合は、一度ご相談
              ください。
              <br />
              なお、財産が多く、調査に時間がかかるなどの理由がある場合、３ヶ月の期間の延長を
              家庭裁判所に申し立てることができます。
              <br />
              また、限定承認は、相続した財産の範囲内で、負債を弁済し、残った財産が
              あれば相続するという制度です。
              <br />
              しかしながら、共同相続人全員で、３ヶ月以内に家庭裁判所に申述し、
              その後の手続きも厳格で時間もかかることから、ほとんど利用されることは
              ありません。
            </dd>
          </dl>

          <dl>
            <dt>２．法定相続</dt>
            <dd>
              法定相続とは、民法で定められた割合で相続することをいいます。
              <br />
              現行法では、配偶者と子が共同相続人である場合は、配偶者２分の１、子が２分の１、
              子がなく、配偶者と直系尊属（両親、祖父母等）が共同相続人の場合は、配偶者３分の２、
              直系尊属３分の１、子も直系尊属もない場合であって、配偶者と兄弟姉妹が共同相続人の
              場合は、配偶者４分の３、兄弟姉妹が４分の１が法定相続分と定められています。
              <br />
              遺言で相続分の指定がない場合や、遺産分割がなされない等の場合、法定相続分による相続となります。
              <br />
              ただし、全ての財産が上記の割合で共有されるので、現金・預貯金のように計算で分けることができない、
              不動産等は、共同相続人全員で共有することになります。
            </dd>
          </dl>

          <dl>
            <dt>３．遺産分割</dt>
            <dd>
              共同相続人全員の合意により、個々の相続財産の権利者を確定する手続きです。
              <br />
              全員の合意が必須条件とはなりますが、内容は自由に決定することができます。
              <br />
              相続財産を分け合う方法だけでなく、相続財産の売却代金を一定の割合で分けあう（換価分割）方法や、
              一人の相続人が相続財産を全部取得する代わりに、他の相続人に一定の金銭を代わりに支払う（代償分割）方法
              を協議の内容として定めることもできます。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SouzokuKettei
