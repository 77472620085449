import React from "react"

const SouzokuCyosa = () => {
  const title = `相続手続で最初にすべきこと`
  const content = `相続手続を始めるにあたって、まずやるべきことは、次の３点です。
  `
  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．遺言書の有無の確認</dt>
            <dd>
              遺言書の有無が分からない場合は、公証役場で公正証書遺言の有無を
              検索することができます。
              <br />
              また、法務局での自筆証書遺言保管制度というものがありますので、
              法務局で保管の有無を調べることができます。
              <br />
              なお、自筆証書遺言の場合は、探して見つけるという他なく、
              さらに、家庭裁判所で検認手続きをする必要があります。
            </dd>

            <dt>２．相続人の確定</dt>
            <dd>
              相続人を確定させるためには、亡くなった方が日本国籍の場合は、
              本籍地の市区町村で戸籍及び、相続人の現在戸籍を取得しなければなりません。
              これは、亡くなった方の生涯で、何名の子がいるのか、または子がいないのかを
              確認するためです。
              <br />
              また、子がいない場合で、直系尊属（両親・祖父母）もいない場合、
              兄弟姉妹相続になります。その場合はさらに、両親の出生から死亡までの戸籍を
              取得しなければなりません。
              <br />
              具体的には最終の戸籍を見て、出生の年代のものまで遡って取得していくという
              作業になりますので、戸籍の収集には一定の相続に関する知識と、戸籍の読み方
              の知識が必要となります。
            </dd>

            <dt>３．相続財産の調査・確定</dt>
            <dd>
              財産のリストのメモを作成し、手続きをする財産に漏れがないようにします。
              <br />
              主なものは、現金、預貯金、株式、保険等金融資産、不動産、自動車、骨董品、
              貴金属宝石類、電話加入権、ゴルフ等レジャー会員権等多くのものが該当します。
              他に、借入金債務等の負債もマイナスの相続財産になります。
              <br />
              なお、みなし相続財産と言われる一定の生前贈与、遺贈の有無も確認するように
              します。相続分の決定や、相続税の申告に必要な財産かどうかを見極める際に必要
              となります。
              <br />
              また、令和元年７月１日以降は、婚姻期間２０年以上の夫婦における、居住用財産
              の夫婦間贈与においては、みなし相続財産にはしない意思表示があると推定される
              旨、新しく民法に規定されています。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SouzokuCyosa
