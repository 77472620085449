import React from "react"

const SouzokuTetsuzuki = () => {
  const title = `相続手続必要書類`
  const content = `相続方法が決まれば、決定した方法に従って、必要書類を揃えて名義変更等の手続きを行います。
  相続登記の場合は不動産所在地の管轄法務局に申請します。必要書類は、相続方法によって異なりますが、主に次のものが必要となります。
    `
  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．法定相続登記の必要書類</dt>
            <dd>
              <ul>
                <li>亡くなった方の出生から死亡までの戸籍</li>
                <li>相続人全員の現在戸籍</li>
                <li>
                  亡くなった方の最後の住民票（本籍地記載）または戸籍の附票
                  （登記住所と最後の住所が異なる場合は、その住所変遷の経緯の分かるものが必要となります）
                </li>
                <li>相続人全員の住民票（本籍地記載）または戸籍の附票</li>
                <li>
                  固定資産税評価証明書または納税通知書等で評価額の分かるもの
                  （登記申請年度のものが必要となります。）
                </li>
                <li>相続放棄者がいる場合は相続放棄申述受理証明書</li>
                <li>司法書士に委任する場合は、委任状</li>
              </ul>
            </dd>
            <dt>２．遺産分割協議による相続登記の必要書類</dt>
            <dd>
              <ul>
                <li>亡くなった方の出生から死亡までの戸籍</li>
                <li>相続人全員の現在戸籍</li>
                <li>
                  亡くなった方の最後の住民票（本籍地記載）または戸籍の附票
                  （登記住所と最後の住所が異なる場合は、その住所変遷の経緯の分かるものが必要となります）
                </li>
                <li>
                  不動産を取得する相続人の住民票（本籍地記載）または戸籍の附票
                </li>
                <li>
                  固定資産税評価証明書または納税通知書等で評価額の分かるもの
                  （登記申請年度のものが必要となります。）
                </li>
                <li>遺産分割協議書</li>
                <li>相続人全員の印鑑証明書</li>
                <li>相続放棄者がいる場合は相続放棄申述受理証明書</li>
                <li>司法書士に委任する場合は、委任状</li>
              </ul>
            </dd>
            <dt>３．遺言による相続登記の必要書類</dt>
            <dd>
              <ul>
                <li>亡くなった方の死亡記載のある戸籍</li>
                <li>不動産を取得する相続人の現在戸籍</li>
                <li>
                  亡くなった方の最後の住民票（本籍地記載）または戸籍の附票
                  （登記住所と最後の住所が異なる場合は、その住所変遷の経緯の分かるものが必要となります）
                </li>
                <li>
                  不動産を取得する相続人の住民票（本籍地記載）または戸籍の附票
                </li>
                <li>
                  固定資産税評価証明書または納税通知書等で評価額の分かるもの
                  （登記申請年度のものが必要となります。）
                </li>
                <li>
                  遺言書（検認が必要な自筆証書遺言については検認済のもの）
                </li>
                <li>相続放棄者がいる場合は相続放棄申述受理証明書</li>
                <li>司法書士に委任する場合は、委任状</li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SouzokuTetsuzuki
