import React from "react"

const SouzokuCost = () => {
  const title = `相続登記の費用`
  const content = `相続登記にかかる費用については、登録免許税、戸籍等の証明書取得費用、不動産の登記事項証明書取得費用、司法書士に依頼した場合の報酬等があります。
    `
  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．登録免許税</dt>
            <dd>
              相続を原因とする所有権移転登記を申請する際に、申請する不動産の固定資産税の評価額の
              １,０００分の４（０.４％）を乗じた金額の登録免許税納付する必要があります。
              <br />
              例えば、土地・建物の固定資産税評価額合計が、２,０００万円だった場合、登録免許税は８万円になります。
              <br />
              よって、弊所へお見積もりのご依頼いただく際には、お手元に対象不動産の固定資産税の評価額の分かるものをご用意いただきますようお願いいたします。
              <br />
              また、相続人以外の人への遺贈を原因とする所有権移転登記は、税率が１,０００分の２０（２％）となります。
            </dd>
            <dt>２．戸籍等の収集費用</dt>
            <dd>
              戸籍は本籍地の市区町村で取得しますが、何通必要になるのかは、人それぞれによって異なります。
              <br />
              戸籍は、婚姻や他の市区町村への転籍、また、改製という行政の手続きによって、その時に有効な記載事項だけを残して、新しい戸籍を作り直すという作業がされるためです。
              そして、以前のものは除籍とういう形で保管されます。
              <br />
              よって、何回婚姻・転籍しているか、また、改製のタインミングによって、必要となる戸籍の通数が異なることになるので、人により戸籍の収集費用は変わってくるということになります。
              <br />
              戸籍の取得にかかる実費は現在戸籍は４５０円、除籍・原戸籍は、７５０円となっています。
            </dd>
            <dt>３．その他の費用</dt>
            <dd>
              その他の費用とし、不動産を何個持っているかによって、不動産の登記事項証明書等の取得費用や、市区町村で固定資産税評価証明書を取得費用等がかかります。
              <br />
              また、司法書士へ依頼された場合のその報酬も費用としてかかることになります。
            </dd>
            <dt>４．登記費用の具体例</dt>
            <dd>
              以上でも、なかなか具体的なイメージを掴みにくいと思いますので、みつわ合同事務所へご依頼いただいた場合の、具体例をご紹介いたします。
              <br />
              ただし、あくまでも、想定事例限定のものとなりますので、個別の御見積については、お気軽にお問い合わせ下さい。
              <br />
              　
              <div className="souzoku__cost__exam">
                <h3>想定条件</h3>
                <ul>
                  <li>管轄法務局：大阪法務局</li>
                  <li>登記申請件数：１件</li>
                  <li>不動産：土地１筆・建物１戸</li>
                  <li>評価額：２,０００万円</li>
                  <li>相続人：配偶者１名、子２名</li>
                  <li>相続方法：遺産分割により配偶者が不動産を取得</li>
                  <li>戸籍・除籍等収集：１０通・実費：１万円</li>
                  <li>事務所来所による手続</li>
                </ul>
                <h4>以上の条件による相続登記費用例</h4>
                <p>２０１,３６８円</p>
                <p>（報酬・実費・消費税（１０％）込）</p>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SouzokuCost
