import React from "react"

const SouzokuTokitoha = () => {
  const title = `相続登記とは`
  const content = `不動産を所有されている方がお亡くなりになると、その方から相続人へ名義を変更する必要が出てきます。このような登記を相続登記といいます。
亡くなられた方の名義のままで放っておいてもすぐに問題となることはありませんが、そのままにしておくと、数世代にわたる相続を一度に解決する必要があり、多数の相続人間で話し合うことになります。
なお、近年の動きとして、相続登記を放置した結果、所有者不明の土地が多数発生しているため、相続登記が義務化される方向で調整されています。
また、期限が定められている手続きがあるので、注意してください。
期限が定められている主な手続きは次のものです。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．相続の放棄・限定承認</dt>
            <dd>３ヶ月以内</dd>

            <dt>２．所得税の準確定申告</dt>
            <dd>４ヶ月以内</dd>

            <dt>３．相続税の申告</dt>
            <dd>１０ヶ月以内</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SouzokuTokitoha
