import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import SouzokuTokitoha from "../components/souzokuDetail/souzokuTokitoha"
import SouzokuCyosa from "../components/souzokuDetail/souzokuCyosa"
import SouzokuKettei from "../components/souzokuDetail/souzokuKettei"
import SouzokuTetsuzuki from "../components/souzokuDetail/souzokuTetsuzuki"
import SouzokuCost from "../components/souzokuDetail/souzokuCost"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"

const Souzoku = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      souzoku: file(relativePath: { eq: "many-papers.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "相続による所有権移転登記の手続き、ご相談、費用、戸籍謄本の収集などについて、大阪の司法書士事務所の司法書士法人みつわ合同事務所がご紹介します。また、提携税理士の紹介もさせていただきます。"

  return (
    <Layout>
      <SEO
        description={description}
        title="相続　所有権移転　登記 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.souzoku.childImageSharp.original.src}
        pageimgw={data.souzoku.childImageSharp.original.width}
        pageimgh={data.souzoku.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"souzoku"}
          fluid={data.souzoku.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"相続"}
        />
        <section className="souzoku">
          <div className="souzoku__container">
            <h1 className="souzoku__title">相続</h1>
            <SouzokuTokitoha />
            <SouzokuCyosa />
            <SouzokuKettei />
            <SouzokuTetsuzuki />
            <SouzokuCost />
            <BlogLink link={"inherit"} linkName={"相続"} />
          </div>
        </section>
        <BackContact link={"/consul/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default Souzoku
